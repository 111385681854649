<template>
  <v-list-item @click="$event => $emit('selectPrinter', printer)">
    <v-list-item-avatar>
      <v-icon>mdi-printer-pos</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ printer.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ printer.model }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content class="text-right">
      <v-list-item-title>
        {{ printer.ipAddress }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
	name: 'PrinterListItem',
	props: {
		printer: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
}
</script>
